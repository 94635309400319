import React from "react";
import { Container, Button } from "react-bootstrap";
// import privacy_policy from "../shared/images/privacy_policy.png";
import metrics from "../shared/images/metrics.png";
// import write from "../shared/images/write.png";

function CenterOfExcellence() {
  return (
    <>
      <div className="banner centerofexcellence-banner"></div>
      <Container className="introAssessment text-white"></Container>
      <div className="card-tc">
        <div className="p-4">
          <img
            src={metrics}
            width="60px"
            alt="CPRA Assessment"
            className="d-inline"
          />
          <h4 className="text-secondary d-inline align-bottom">
            Are you ready for CPRA?
          </h4>
          <h6 className="ms-2 mt-3">
            Use the WireWheel assessment to determine:
          </h6>
          <ul className="ms-2">
            <li>Your relative readiness</li>
            <li>The actions you need to take to prepare internally</li>
            <li>The actions you need to take with 3rd parties</li>
          </ul>
        </div>
        <div className="header_btn">
          <Button
            href="/CPRAAssessment"
            variant="primary"
            className="text-white p-3"
            style={{ borderRadius: "25px", width: "75%" }}
          >
            Take the CPRA Readiness Assessment
          </Button>
        </div>
      </div>
    </>
  );
}

export default CenterOfExcellence;
