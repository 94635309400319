import React from "react";
import { Nav, Navbar, Button, Image } from "react-bootstrap";
import Logo from "../images/logo.png";

function Header() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-custom">
      <Navbar.Brand href="https://www.wirewheel.io/" target="_blank">
        <Image
          src={Logo}
          alt="WireWheel logo"
          className="p-2 ms-5"
          width="250px"
        />
      </Navbar.Brand>
      <div className="flexarea"></div>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto me-5">
          <Button href="/CPRAAssessment" variant="primary" className="nav-btn">
            Take the CPRA Readiness Assessment
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
