import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";

function CPRAAssessment() {
  const queryString = window.location.pathname.split("/").join("");
  console.log("queryString", queryString);

  return (
    <>
      <SrrHeader
        heading="CPRA Assessment"
        breadcrumb_path1="CENTER OF EXCELLENCE"
        breadcrumb_path2="CPRA ASSESSMENT"
      />

      <section className="form-section my-3">
        <ImportScript
          src={process.env.REACT_APP_CPRA_COE_CPRA_ASSESSMENT}
          pagId={process.env.REACT_APP_CPRA_COE_CPRA_ASSESSMENT_PGID}
          queryString={queryString}
        />
      </section>
    </>
  );
}

export default CPRAAssessment;
